$menu-font-color: black;

$dark-gray: #231e1f;
$white: #fff;
$black: #000;

$brand-accent: #000000;
$brand-accent-secondary: #37dbdb;

.cart-product-count{
	background-color: #37dbdb;
}

ion-title{
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}

ion-content {
	background-color: #fff !important;
	--background: #ffffff;
}

// body{
// 	background: url('~assets/images/nacho/background.jpg') repeat-x center center / cover !important;
// };

// #background-content {
//     background-image: url('~assets/images/nacho/background.jpg'	) !important;
//     background-position: center !important;
//     background-size: cover !important;     	    
// }

.brand-background{
	background-image: url('~assets/images/homescreen.jpg');
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

ion-menu-button{
  --color:#fff !important;
   color:#fff !important;   
}

ion-menu-buttons{
  --color:#fff !important;
   color:#fff !important;
}

ion-back-button{
	--color: $menu-font-color;
}

ion-tab-button{
  --color-focused: var(--ion-color-secondary);
  --color-selected: var(--ion-color-secondary);
}

ion-footer{
    background-color: #fff;
    border-top: 1px solid #ebe7e7;	
}

ion-button.round button{
	border-radius: 64px !important;
}

ion-button{
	--color: $menu-font-color;
	--ion-color-success: $brand-accent;


	--ion-color-success: #93D500;
	--ion-color-success-rgb: 0,102,0;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #1998B5;
	--ion-color-success-tint: #1998B5;	

	--ion-color-light: #A7CC46;
	--ion-color-light-rgb: 0,102,0;
	--ion-color-light-contrast: #ffffff;
	--ion-color-light-contrast-rgb: 255,255,255;
	--ion-color-light-shade: #95B73F;
	--ion-color-light-tint: #95B73F;

	// --border-radius: 64px !important;

	// --padding-bottom: 1.5rem;
	// --padding-top: 1.5rem;
	// --padding-start: 2.6rem;
	// --padding-end: 2.6rem;	
}

ion-progress-bar{
	--background: #ffffff36;
	--progress-background: var(--ion-color-secondary);
}

ion-tab-bar{
	--background: #ffffff;
	--border: 000000;
	--color: #000000;
}

ion-tab-button{
	--color-selected: #37dbdb;
}

ion-button{
	font-weight: bold !important;	
}

ion-toolbar{
	--background: #282c30 !important;
	--border-color: var(--ion-color-primary);
	--background: var(--ion-color-primary) !important;
	--color: #fff !important;
	font-weight: bold !important;
}

// ion-header {
// 	background-color: #CB007B;	
// }

ion-toggle{
	--background-checked: #ff9100;
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

ion-checkbox {
	--background: #fff;

	--border-color: #999;
	--border-color-checked: $black;

	--checkmark-color: #fff;
	--background-checked: #000;	
}

ion-slides{
	--bullet-background: white;
	--bullet-background-active: #ff9100;
}

.order-checkout .action-sheet-title{
    font-size: 1.4em !important;
    color: #000;
    font-weight: bold;    
}

.order-checkout .action-sheet-title{
    font-size: 1.4em !important;
    color: #000;
    font-weight: bold;    
}

.label-stacked{
	font-weight: bolder;
}

.swiper-pagination-bullet {
	background: white;
	opacity: 0.8 !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
	background: #ff9100;
}

.action-sheet-button-inner{
	color: #000;	
}

//Refresher
.refresh-zindex{
	z-index: 0 !important;
}

.refresh-nozindex{
	z-index: -1 !important;
}

//Alert
.alert-head{
	background-color: $dark-gray !important;
}

.alert-title{
	color: $white !important;
}

.alert-button{
	--color:$menu-font-color !important;
	color:$menu-font-color !important;
}

.alert-message{
	background-color: $dark-gray !important;
	color: $white !important;
}

.alert-radio-label{
	color: $black !important;
}

.document-link{
    color: #282828;
    text-decoration: underline;
    display: flex;
    align-items: center;
    cursor: pointer;
		font-weight: bold;
}

.product-counter{
    background-color: #282828;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-radius: 64px;
    text-align: center;
    height: 40px;
    width: 40px;
}

.footer-button, .footer-button-transparent {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ebe7e7;
	z-index: 10;
	padding-bottom: 10px;
	
	@media screen and (min-width: 992px) {			
		width: 600px;
		margin: auto;
		border: none !important;
	}   	
}

.footer-button-transparent {
	background-color: transparent !important;
	border: none !important;
}

.category-pill.selected {
	background-color: $brand-accent-secondary !important;
}

.anchor-button{
	text-decoration: underline;
	cursor: pointer;
}

// .phone-number
// {
// 	border-bottom: 1px solid #c8c7cc !important;
// }

.phone-number.text-input{
    font-size: 1rem;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    display: inline-block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    border: 0;
    background: transparent;    
    padding: 8px 0 8px 0;
}

.phone-number.text-input:focus{
	outline: none;
}

.phone-block{
    margin-left: 20px;
}

.phone{
    color: #fff;
    text-decoration: underline;
}

.product-footer{
    flex: 0 0 70px;
    padding-top: 5px;
    border-top: 1px solid #e4e4e4;
}

.order-total {
	margin-left: 5px;
	font-weight: bold;
}

product-modifier-block{
	display: block;
}

.t-c-b {
	color: black !important;
}

.spacer-10{
    display: block;
    height: 10px
}

.stretchable-container{
	// height: 100vh;

	@media screen and (min-width: 992px) {		
		height: 100%;
		width: 600px;
		margin: auto;
	}   	
}

.modal-wrapper {	
	@media screen and (min-width: 768px) {		
	    height: 95% !important;
	}   	
}

.brand-text-color{
	color: $brand-accent !important;;
}

.no-background{
	--background: none !important;
}


.list-form-container, .form-container{
    padding: 0px 0px 15px 0px;
    border-radius: 5px;
    margin: 10px;    
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}

.list-container{
    border-radius: 5px;
    margin: 10px;    
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;	
}

.form-container{
	padding: 15px !important;
	background-color: white;
}

.header-text{
    color: #000;
    margin-left: 16px;
    font-weight: bolder;	
	margin-top: 40px;
    margin-bottom: 0px;
}

.peekabond-map, .peekabond-map .map{
    width: 100%;
    height: 100%;    
}

.gm-style .gm-style-iw-c {
	padding: 8px 12px 12px 8px;
}

.business-image-map{
    height: 60px;
    width: 60px;
    border-radius: 5px;
}	

ion-title{
    color: #fff;
    font-size: 22px;

    text-align: center;
    pointer-events: auto;
}

ion-modal{
	@media only screen and (min-width: 768px) and (min-height: 768px)
	{
		--width: 100%;
		--height: 100%;
	}	
}


html {
  --lh: 1.4rem;
  line-height: var(--lh);
}

h2 {
	font-weight: bold;
}

.truncate-overflow {
	--max-lines: 3;
	max-height: calc(var(--lh) * var(--max-lines));
	overflow: hidden;
}

.paddingless-grid{
    padding:0px;
}

.paddingless-grid ion-col{
    padding:0px;
}

//.truncate-overflow::before {
//	content: "...";
//	position: absolute;
//	bottom: 0;
//	right: 0;
//}

//.truncate-overflow::after {
//	content: "";
//	position: absolute;
//	right: 0; /* note: not using bottom */
//	width: 1rem;
//	height: 1rem;
//	background: white;
//}

.scanner-ui { display: none; }
.scanner-hide { visibility: visible; }

body.qrscanner { background-color: transparent; }
body.qrscanner .scanner-ui { display: block; }
body.qrscanner .scanner-hide { visibility: hidden; }