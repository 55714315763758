.flex{
    display: flex;
}

.f-g-1{
    flex-grow: 1;
}

.f-j-r{
    justify-content: right;
}

.f-j-l{
    justify-content: left;
}

.f-j-c-c{
    justify-content: center;
}

.f-s-b{
    justify-content: space-between;
}

.f-a-i-c{
    align-items: center;
}

.f-d-c{
    flex-direction: column;
}

.f-d-r{
    flex-direction: row;
}

.f-a-c-s{
    align-content: stretch;
}

.f-wrap{
    flex-wrap: wrap
}

.f-quick-center{
    display: flex;
    justify-content: center;
    align-items: center;    
}
