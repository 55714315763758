.dynamic-form {
	padding: 10px 16px 10px 16px;
	border-radius: 10px;		
}

.dynamic-form {
	.form-group {
		margin-bottom: 10px;
	}

	.form-control{
		border-left: none !important;
		border-right: none !important;
		border-top: none !important;
	}

	.form-control.dropdown{		
		border-bottom: 1px solid #999 !important;
		border-radius: 0px !important;
		height: 40px !important;
		padding-left: 0px !important;
	}

	.choices__item{
		font-size: 1em;
		margin-top: 8px;
	}	

	.choices[data-type*=select-one] .choices__input {
	    display: block;
	    width: 100%;
	    padding: 10px;
	    border-bottom: 1px solid #ddd;
	    background-color: #fff;
	    margin: 0;
	}	

	input[type=checkbox] {
		margin-right: 5px;
	}

	.form-check.checkbox span{
		padding-left: 5px;
	}

	input:not(input[type=checkbox]):focus, select:focus, textarea:focus{
		outline: none;
	}

	input:not(input[type=checkbox]), select, textarea{
	    border-bottom: 1px solid #999 !important
	}

	input:not(input[type=checkbox]).ng-untouched.ng-valid,
	input:not(input[type=checkbox]).ng-touched.ng-dirty.ng-valid {
	    border-bottom: 1px solid #32db64 !important;
	}   

	input.ng-touched.ng-invalid {
	    border-bottom: 1px solid red !important;
	}   

	input:not(input[type=checkbox]), select, textarea{
	    font-size: 1rem;
	    -moz-appearance: none;
	    -ms-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;
	    border-radius: 0;
	    display: inline-block;
	    -webkit-box-flex: 1;
	    -webkit-flex: 1;
	    -ms-flex: 1;
	    flex: 1;
	    width: 100%;
	    border: 0;
	    background: transparent;    
	    padding: 8px 0 8px 0;
	}

	input[type=checkbox] {
		margin-right: 5px;
    	margin-left: 3px;
		transform: scale(1.5);
	}	

	.formio-select-autocomplete-input {
	    display: none !important;
	}	



}