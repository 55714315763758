.n-m-t{
   margin-top: 0px; 
}

.b-s-0 {
    border-spacing: 0px;    
}

.t-a-c{
    text-align: center !important;
}

.t-a-l{
    text-align: left !important;
}

.m-i{
    margin: initial !important;
}

.t-a-r{
    text-align: right !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-t-0{
    margin-top:0 !important;
}
    
.m-t-10{
    margin-top: 10px !important;
}

.m-l-10{
    margin-left: 10px !important;
}

.m-l-16{
    margin-left: 16px !important;
}

.m-l-18{
    margin-left: 18px !important;
}

.m-l-20{
    margin-left: 20px !important;
}

.m-l-24{
    margin-left: 24px !important;
}

.m-l-5{
    margin-left: 5px !important;
}

.m-10{
    margin: 10px !important;
}

.m-5{
    margin: 5px !important;
}

.m-t-b-10{
    margin: 10px 0 10px 0 !important;
}

.m-t-b-5{
    margin: 5px 0 5px 0 !important;
}

.m-t-b-20{
    margin: 20px 0 20px 0 !important;
}

.m-t-20 {
    margin-top:20px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-50 {
    margin-top: 50px !important;
}

.m-l-10 {
    margin-left:10px !important;
}

.m-r-10 {
    margin-right:10px !important;
}

.m-t-b-0 {
    margin-top:0px !important;
    margin-bottom:0px !important;
}

.m-b-10 {
    margin-bottom:10px !important;
}

.m-b-15 {
    margin-bottom:15px !important;
}

.m-b-5 {
    margin-bottom:5px !important;
}

.m-b-20 {
    margin-bottom:20px !important;
}

.m-b-24 {
    margin-bottom:24px !important;
}

.m-t-20{
    margin-top: 20px;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-l-r-24{
    margin: 0 24px 0 24px !important;
}

.m-l-r-10{
    margin: 0 10px 0 10px !important;
}

.m-l-r-5{
    margin: 0 5px 0 5px !important;
}

.m-l-10-i{
    margin-left:10px !important;
}

.m-l-r-t-24{
    padding: 10px 10px 0px 10px;    
}

.p-l-r-16{
    padding: 0 16px 0 16px !important;
}

.p-t-b-50{
    padding: 50px 0 50px 0;
}

.p-t-b-6{
    padding: 6px 0 6px 0;
}

.p-t-b-5{
    padding: 5px 0 5px 0;
}

.p-t-b-10{
    padding: 10px 0 10px 0;
}

.p-t-10{
    padding-top: 10px !important;
}

.p-t-5{
    padding-top: 5px !important;
}

.p-l-5{
    padding-left: 5px !important;
}

.p-r-5{
    padding-right: 5px !important;
}

.p-l-10{
    padding-left: 10px !important;
}

.p-r-10{
    padding-right: 10px !important;
}


.p-b-5{
    padding-bottom: 5px !important;
}


.p-b-10{
    padding-bottom: 10px !important;
}

.p-10{    
    padding: 10px !important;
}

.b-r-10{
    border-radius: 10px;
}

.b-r-15{
    border-radius: 15px;
}

.f-w-b{
    font-weight: bold !important;
}

.w-s-n{
    white-space: normal;    
}

.hidden {
    display: none !important;
}

.d-g {
    display: grid !important;
}

.o-h {
    overflow: hidden !important;
}

.pull-left {
    float:left;
}

.pull-right {
    float:right;
}

.no-padding {
    padding: 0;
}

.no-wrap-text{
    text-overflow: ellipsis;    
    overflow: hidden;
    white-space: nowrap;    
}

.f-w {
    width: 100%;
}

.d-il-b{
    display: inline-block;
}

.d-f{
    display: flex;
}
.b-t-5{
    bottom: 5px;
}

.invisible{
    visibility: hidden;
}

.b-s-l{
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175) !important;   
}

.c-p{
    cursor: pointer;
}

.h-100-p{
    height: 100% !important;
}

.w-100-p{
    width: 100% !important;
}

.w-90-p{
    width: 90% !important;   
}

.w-80-p{
    width: 80% !important;      
}

.w-70-p{
    width: 70% !important;          
}

.w-60-p{
    width: 60% !important;             
}

.w-50-p{
    width: 50% !important;             
}

.w-25-p{
    width: 25% !important;             
}

.t-t-cap{
    text-transform: capitalize;
}

.spacer{
    height: 200px;
    width: 100%;
}

.border-red{
    border: 1px solid red;
}

.border-black{
    border: 1px solid #000;
}

.border-blue{
    border: 1px solid #039be5;
}

.border-top-light-grey{
    border-top: 1px solid #ebe7e7;
}

.border-bottom-light-grey{
    border-bottom: 1px solid #ebe7e7;
}

.image-loader-container{
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }    
}

.pos-r{
    position: relative;
}

.pos-a{
    position: absolute;
}

.no-opacity{
    opacity: 0;;
}

.error-label{
    color: red !important;
}

.z-i-100{
    z-index: 100;
}

.b-t-l-g{
    border-top: 1px solid #ebe7e7;
}

.b-t-b-g{
    border-bottom: 1px solid #ebe7e7;
}

